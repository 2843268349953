import { _APP_DATA } from "../../data/AppData";
import { _LoadDataChild, _mapChilds } from "../../data/services/Kids";
import _L from "../../utils/Labels";
import { BClsRoom } from "../bases/BClsRoom";
import { LOADING } from "../components/Loading";
import ic_classroom from '/icons/ic_classroom.svg?raw'


export class ClassRoom extends BClsRoom {

    constructor() {
        super();

        this.IS_EXTEMPORAL = false;
    }

    protected LoadChild(): void {
        this.emptyTag
            ._SetText(_L("classroom.no_childs"))
            ._SetSVGIcon(ic_classroom)

        this.LoadFromMapData();

        if (_mapChilds.size > 0)
            LOADING.Dismiss();

        _LoadDataChild((_) => {
            LOADING.Dismiss();

            this.LoadFromMapData();
        });
    }

    private LoadFromMapData() {
        const resList = Array.from(_mapChilds.values());
        if (resList.length == 0) {
            this.RefreshEmptyTag()
            return
        }

        this.childList = resList.filter(o => {
            let idGroup = o.GrupoActivo < 0 ? (o.GrupoActivo * -1) : o.GrupoActivo;
            return o.EnKinder && idGroup == _APP_DATA.userData.IdGrupo;
        });

        this.SetListBubbleChild();
        this.DragAllItems();

        this.RefreshEmptyTag()
    }

    private RefreshEmptyTag() {
        if (this.childList.length) {
            this.emptyTag?._Remove()
        } else {
            this.emptyTag?._ShowTag()
        }
    }
}