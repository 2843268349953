import { Selection, ascending, select, timeDay } from "d3";
import "../../../styles/pages/Home.scss";
import { _APP_DATA } from "../../data/AppData";
import { IChild, ITodaySchoolHour } from "../../data/models/Entities";
import { _LoadDataChild, _LoadSchoolHour, _LoadTodaySchoolHour, _mapChilds, _mapSchoolHourChild } from "../../data/services/Kids";
import { _EnterGroup, _OutGroup, _OutKinder } from "../../data/services/Kinder";
import { _SignOut, _ValidateSession } from "../../data/services/UserInfo";
import { FormatDateToHour, _RestartAndCreateDB } from "../../data/utils/General";
import { _PushState } from "../../routes/UIManager";
import { _Logout } from "../../utils/Device";
import { _TimeToInputFmt } from "../../utils/General";
import _L, { _HttpMsg } from "../../utils/Labels";
import { BaseUI } from "../bases/BaseUI";
import { Button } from "../components/Button";
import { ConfirmDialog } from "../components/ConfirmDialog";
import { EmptyTag } from "../components/EmptyTag";
import { LOADING } from "../components/Loading";
import { Spinner } from "../components/Spinner";
import { CreateToast, ShowToast } from "../components/Toast";
import { _CreateElementFromHTML } from "../utils/General";
import ic_checkout from '/icons/ic_checkout.svg?raw';
import ic_departure from '/icons/ic_departure.svg?raw';
import ic_enter from '/icons/ic_enter.svg?raw';
import ic_profile from '/icons/ic_profile.svg?raw';
import ic_profile_default from '/icons/ic_profile_default.svg';
import ic_tick from '/icons/ic_tick.svg?raw';

export class Home extends BaseUI {

    private _isEntrace: boolean = true;//Identificar Entra / salida
    private btnProfile: Selection<SVGElement, any, any, any> | null = null;
    private divListContainer: Selection<HTMLDivElement, any, any, any>;
    private btnEnterDeparture: Button;
    private childSelected: IChild;
    private todayScholHours: ITodaySchoolHour[];

    private btnChangeGroup: Selection<HTMLButtonElement, any, any, any> | null = null;
    private _tmmerSchoolHrs: NodeJS.Timeout;
    private emptyTag: EmptyTag

    constructor() {
        super();

        LOADING.Show();

        this.CreateEnterList();
        this.AddChangeGroupBtn();
        this.OnInit();
    }

    public OnInit(): void {
        this.LoadSchoolHours(false);
        this._tmmerSchoolHrs = setInterval(() => this.LoadSchoolHours(true), 30000);//Actualizar cada 30seg

        this._CheckSessionStatus();
        this.emptyTag = new EmptyTag(this.divListContainer.node())
    }

    private _CheckSessionStatus() {
        _ValidateSession((result) => {
            if (result >= 0) return;

            _SignOut(async (resStatus) => {
                if (resStatus === 1) {
                    await _RestartAndCreateDB();
                    _Logout();
                }
            });
        });
    }

    private LoadSchoolHours(updateWhenHasData: boolean) {
        _LoadSchoolHour((res) => {
            if (!updateWhenHasData || (updateWhenHasData && res.length > 0))
                this.LoadChild();
        });
    }

    private LoadChild() {
        _LoadDataChild((_) => {
            LOADING.Dismiss();

            this.SetDataContent();
        });
    }

    private CreateEnterList() {
        this._isEntrace = true;
        let divHeadBtn = this.bodyContainer.append("div").classed("head-btn", true);
        this.btnEnterDeparture = new Button(divHeadBtn)
            .Title(_L("E/S.entrada"))
            .Image(ic_enter, true)
            .Classed("btn-enter")
            .OnClick(() => {
                this.EmptyList();

                this._isEntrace = !this._isEntrace;
                this.btnEnterDeparture.title = this._isEntrace ? _L("E/S.entrada") : _L("E/S.salida");
                this.btnEnterDeparture.image = this._isEntrace ? ic_enter : ic_departure;

                this.btnEnterDeparture._button.classed("btn-enter", this._isEntrace);
                this.btnEnterDeparture._button.classed("btn-departure", !this._isEntrace);

                this.SetDataContent();
            });

        this.divListContainer = this.bodyContainer.append("div").classed("div-list", true)
    }

    protected AddChangeGroupBtn() {
        this.btnChangeGroup = this.bodyContainer.append("button")
            .style("display", "none")
            .classed("btn-change-group", true)
            .classed("button-circle", true)
            .html(ic_checkout)
            .on("click", () => {
                this.OnCheckGroup();
            });
    }

    private SetDataContent() {
        let childsFilter = Array.from(_mapChilds.values())
            .filter((d) => this._isEntrace ? d.GrupoActivo == 0 : (d.EnKinder && d.GrupoActivo == _APP_DATA.userData.IdGrupo))
            .sort((a, b) => ascending(a.Nombre, b.Nombre));

        let listGroupsHour: { hour: string, time: number, childs: IChild[] }[] = [];
        childsFilter.forEach(item => {
            let child_schedule = _mapSchoolHourChild.get(item.IdChild)// as Data.Entities.iScheduleChild;
            if (!child_schedule) return;

            let groupName: string = _L("E/S.noopera");
            let dt: Date | null | undefined;

            if (this._isEntrace) {
                if (child_schedule.Entrada) {
                    dt = (child_schedule.Entrada.getTime() - timeDay(child_schedule.Entrada).getTime() > 0) ? child_schedule.Entrada : null;

                }
            } else {
                if (child_schedule.Salida)
                    dt = (child_schedule.Salida.getTime() - timeDay(child_schedule.Salida).getTime() > 0) ? child_schedule.Salida : null;
            }

            if (dt !== undefined)
                groupName = dt !== null ? FormatDateToHour(dt) : _L("E/S.sinhorarioext");

            const time = (dt ? (dt.getHours() * 60 + dt.getMinutes()) : 24 * 60); //Tiempo en Minutos. (24*60) => Poner los No Habilitados al final.

            let lsChilds = listGroupsHour.find(o => o.hour == groupName);
            if (!lsChilds) {
                lsChilds = { hour: groupName, time: time, childs: [] };
                listGroupsHour.push(lsChilds);
            }

            lsChilds.childs.push(item);
        });

        listGroupsHour.sort((a, b) => a.time - b.time);

        this.SetListHour(listGroupsHour);

        if (childsFilter.length)
            this.emptyTag._Remove()
        else
            this.emptyTag
                ._SetColor(this._isEntrace ? "var(--color-green)" : "var(--color-salida)")
                ._SetText(this._isEntrace ? _L("E/S.no_child_enter") : _L("E/S.no_child_exit"))
                ._SetSVGIcon(this._isEntrace ? ic_enter : ic_departure)
                ._ShowTag()
    }


    private SetListHour(listData: { hour: string, childs: IChild[] }[]) {
        const cardChild = this.divListContainer.selectAll<HTMLDivElement, any>(".group-hour-child").data(listData);

        cardChild.exit().remove();
        cardChild.enter().append("div")
            .classed("group-hour-child", true)
            .each((_, i, divs) => {
                const elemnt = select(divs[i]);
                let headHour = elemnt.append("div").classed("head-hour", true);
                headHour.append("div").attr("class", this._isEntrace ? 'primary' : 'secundary')
                    .append("span");
                headHour.append("hr")

                elemnt.append("div").classed("list-container-child", true);
            })
            .merge(cardChild)
            .each((datum, i, divs) => {
                const elemnt = select(divs[i]);
                elemnt.select(".head-hour").select("div>span").text(datum.hour)

                this.SetListChild(elemnt.select(".list-container-child"), datum.childs)
            });
    }

    private SetListChild(parent: Selection<HTMLDivElement, any, any, any>, listData: IChild[]) {
        const cardsChild = parent.selectAll<HTMLDivElement, IChild>(".cards-child").data(listData);

        const self = this;
        cardsChild.exit().remove();
        cardsChild.enter().append("div")
            .classed("cards-child", true)
            .each((_, i, divs) => {
                const elemnt = select(divs[i]);
                let card = elemnt.append("div").classed("card-child", true);
                card.append("img").classed("prof-child", true)
                    .attr("onerror", "this.src='" + ic_profile_default + "'");

                let crdTexts = card.append("div").classed("text-container", true);
                crdTexts.append("h4");
                crdTexts.append("h5");

                let btnDone = elemnt.append("div")
                    .classed("btn-done", true)
                // .style("display", "none");
                btnDone.html(ic_tick);
            })
            .merge(cardsChild)
            .on('click', function () {
                let elm = select(this);
                const isActive = elm.classed("active");

                self.bodyContainer.selectAll(".cards-child.active").classed("active", false);
                elm.classed("active", !isActive);

                const dtum = !isActive ? elm.datum() : null;
                self.SelectChild(dtum as any, elm);
            })
            .each((datum, i, divs) => {
                const elemnt = select(divs[i]);
                if (elemnt.classed("active") && datum.IdChild != this.childSelected?.IdChild) {
                    elemnt.classed("active", false);
                }
                let card = elemnt.select(".card-child");
                card.select(".prof-child").attr("src", datum.urlPhoto || "")

                card.select("div>h4").text(datum.Nombre);
                card.select("div>h5").text(datum.ApPaterno + " " + datum.ApMaterno);

                elemnt.select(".btn-done").on("click", () => {
                    this.OnSaveClick(datum, elemnt);
                });
            });
    }

    private OnSaveClick(datum: IChild, elemt: Selection<HTMLDivElement, any, any, any>) {
        const isEnabled = elemt.property("enabled");
        if (!isEnabled) {
            ShowToast(this._isEntrace ? _L("E/S.entrada") : _L("E/S.salida"), _L("E/S.alumno_fuera", datum.Nombre), 'info');
            return;
        }

        if (this._isEntrace) {
            this.SaveSelectedChild(datum);
        } else {
            new ConfirmDialog()
                .SetDescription(_L("E/S.confirm_salida", datum.Nombre))
                .SetOnConfirm(() => {
                    this.SaveSelectedChild(datum);
                });
        }
    }

    private SaveSelectedChild(childSelected: IChild,) {
        LOADING.Show();
        // this.childSelected = datum;

        if (this._isEntrace) {
            _EnterGroup(childSelected.IdChild, (STATUS) => {
                const title = childSelected.EnKinder ? _L("E/S.entraa_grupo") : _L("E/S.entraa_escuela");

                if (STATUS === 1) {
                    this.ShowToastSuccess(childSelected);

                    childSelected.EnKinder = true;
                    childSelected.GrupoActivo = _APP_DATA.userData.IdGrupo;

                    _mapChilds.set(childSelected.IdChild, childSelected);

                    this.LoadChild();

                } else if (STATUS === -1) ShowToast(title, _L("E/S.alumno_noval"), "info");
                else ShowToast(title, _L("general.error"), "error");

                LOADING.Dismiss();
            });
        } else {//Salida
            _OutKinder(childSelected.IdChild, (STATUS) => {
                if (STATUS === 1) {
                    childSelected.EnKinder = false;
                    childSelected.GrupoActivo = 0;

                    this.ShowToastSuccess(childSelected);

                    _mapChilds.set(childSelected.IdChild, childSelected);
                    this.LoadChild();
                } else
                    ShowToast("Salida", _L("general.error"), "error");

                LOADING.Dismiss();
            });
        }
    }

    private ShowToastSuccess(datum: IChild) {
        CreateToast({
            title: _L("E/S.success_title"),
            subtitle: this._isEntrace ? _L("E/S.alumno_llego_esc", datum.Nombre) : _L("E/S.alumno_salio_esc", datum.Nombre),
            position: 'BOTTOM-RIGHT',
        });
    }

    private SelectChild(datum: IChild, elemt: Selection<HTMLDivElement, any, any, any>) {
        this.childSelected = datum;
        elemt.property("enabled", !this._isEntrace);
        this.btnChangeGroup?.style("display", "none")
        this.todayScholHours = [];

        if (!this.childSelected) {
            this.btnProfile?.remove();
            this.btnProfile = null;
            return;
        }

        this.CreateBtnProfile();

        Spinner._ShowSpinner(elemt);
        const omit = !this._isEntrace;
        const idChild = this.childSelected.IdChild;
        _LoadTodaySchoolHour(idChild, omit, (status, result) => {
            Spinner._RemoveSpinner(elemt);
            if (status < 1 || !this.childSelected || idChild !== this.childSelected.IdChild) return;

            if (result.length == 0) {
                elemt.property("enabled", true);
                return;
            }

            this.todayScholHours = result;

            const scheduleFilter = result.filter((d) => d.IdGrupo == _APP_DATA.userData.IdGrupo);
            const enabled = !this._isEntrace || scheduleFilter.length > 0;
            elemt.property("enabled", enabled);

            this.btnChangeGroup?.style("display", this._isEntrace ? "none" : "block");
        });
    }

    private EmptyList() {
        this.SetListHour([]);
        this.btnChangeGroup?.style("display", "none")
        this.childSelected = null as any;
        this.btnProfile?.remove();
        this.btnProfile = null;
    }

    private CreateBtnProfile() {
        if (!!this.btnProfile) return;

        const dOptions = this.headerContainer.select<HTMLDivElement>(".div-options").node();

        const profileBtn = _CreateElementFromHTML(ic_profile) as SVGElement;
        this.btnProfile = select(profileBtn).attr("class", 'img-options-header')
            .on("click", () => {
                _PushState("profile", this.childSelected);
            });
        dOptions.insertAdjacentElement("afterbegin", profileBtn);
    }

    private OnCheckGroup(): void {
        if (this._isEntrace) return;

        if (!this.childSelected) {
            ShowToast(_L("E/S.otrogrupo_title"), _L("E/S.selecciona"), "info");
            return;
        }

        const dataHoursGroup: ITodaySchoolHour[] = [];
        this.todayScholHours.forEach((item) => {
            var msg = item.Grupo + " (" + _TimeToInputFmt(item.Entrada) + ")";
            const objTemp = item as any;
            objTemp.label = msg;
            dataHoursGroup.push(objTemp);
        });

        new ConfirmDialog()
            .SetTitle(_L("E/S.envia_a", this.childSelected.Nombre))
            .AddRadioOptions(dataHoursGroup)
            .SetOnConfirm((val: ITodaySchoolHour) => {
                this.ValidateOuGroup(val);
            });
    }

    private ValidateOuGroup(datum: ITodaySchoolHour) {
        if (!datum) return;

        new ConfirmDialog()
            .SetDescription(_L("E/S.confirm_enviar_a", this.childSelected.Nombre, datum.Grupo))
            .SetOnConfirm(() => {
                LOADING.Show();

                _OutGroup(this.childSelected.IdChild, datum.IdGrupo, (STATUS) => {
                    if (STATUS === 1) {
                        this.childSelected.GrupoActivo = datum.IdGrupo;

                        _mapChilds.set(this.childSelected.IdChild, this.childSelected);
                        ShowToast(_L("E/S.otrogrupo_title"), _L("E/S.alumno_salio_g", this.childSelected.Nombre), "success")

                        this.SetDataContent();
                        this.childSelected = null as any;
                        this.btnChangeGroup?.style("display", "none");
                    }
                    else
                        ShowToast(_L("E/S.otrogrupo_title"), _HttpMsg("grupo/Salida", STATUS));

                    LOADING.Dismiss();
                });
            });
    }

    public OnDestroy(): void {
        super.OnDestroy();

        clearInterval(this._tmmerSchoolHrs);
    }

}