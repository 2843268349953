
export class EmptyTag {
    private parentElem: HTMLElement
    private containerElem: HTMLDivElement
    private labelElem: HTMLLabelElement
    private iconContElem: HTMLDivElement

    constructor(parent: HTMLElement) {
        this.parentElem = parent
        this.containerElem = document.createElement("div")
        this.containerElem.className = "empty_tag"
        this.labelElem = document.createElement("label")
        this.iconContElem = document.createElement("div")

        this.containerElem.append(this.iconContElem)
        this.containerElem.append(document.createElement("br"))
        this.containerElem.append(this.labelElem)
    }

    private UpdateIconColor() {
        const icon = this.iconContElem.querySelector("svg")
        if (!icon) return
        icon.style.fill = this.iconContElem.style.fill
    }

    public _SetParent(parent: HTMLElement): this {
        this.parentElem = parent
        if (this.containerElem.parentElement) {
            this.parentElem.append(this.containerElem)
        }
        return this
    }

    public _SetColor(color: string): this {
        this.labelElem.style.color = color
        this.iconContElem.style.fill = color
        this.UpdateIconColor()
        return this
    }

    public _SetText(text: string): this {
        this.labelElem.textContent = text
        return this
    }

    public _SetSVGIcon(iconSVG: string): this {
        this.iconContElem.innerHTML = iconSVG
        this.UpdateIconColor()
        return this
    }

    public _ShowTag(): this {
        this.containerElem
        setTimeout(() => this.parentElem.append(this.containerElem))
        return this
    }

    public _Remove(): this {
        setTimeout(() => this.containerElem.remove())
        return this
    }
}