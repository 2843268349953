import { BotSendError } from "../../utils/AlertBot";
import { apiLoadWeather } from "../API";
import { _APP_DATA } from "../AppData";
import { RequestData } from "../utils/RequestData";

export interface IWeather {
    id: number;
    date: Date;
    description: string;
    icon: string;
    main: string;
    day: number;
    min: number;
    max: number;
}

export function _LoadWeatherInfo(callback: (weather: Array<IWeather>) => void): void {
    let params = {
        IdEscuela: _APP_DATA.userData.IdKinder
    }

    new RequestData<any>(apiLoadWeather)
        .Params(params)
        .Completion((dataResponse, error) => {

            if (error != null || !dataResponse || !dataResponse.daily) {
                console.warn(error, dataResponse);
                BotSendError(error ? error : new Error("failed to load weather"), JSON.stringify(dataResponse))
                if (callback) callback(null);
                return
            }

            let daily = <Array<any>>dataResponse.daily;
            let result: IWeather[] = [];

            daily.forEach((item, i) => {
                let value = {
                    id: (i + 1),
                    date: new Date(),
                    description: item.weather[0].description,
                    main: item.weather[0].main,
                    icon: item.weather[0].icon,

                    day: item.temp.day,
                    min: item.temp.min,
                    max: item.temp.max
                };

                value.date.setDate(value.date.getDate() + i)

                result.push(value);
            });

            if (callback) callback(result);
        }).Get();
}