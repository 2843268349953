import { select } from 'd3';
import "../../../styles/pages/Event-evaluation.scss";
import { ECalificacionTipoEval, EEventId, ICalificacionPendiente } from "../../data/models/Entities";
import { _AsignarCalificacion, _LoadPendientes } from "../../data/services/Calificacion";
import { _DateToInputFmt } from "../../utils/General";
import _L, { _HttpMsg } from "../../utils/Labels";
import { _DateFormatStandar } from "../../utils/Time";
import { BaseUIEvent } from "../bases/BaseUIEvent";
import { EmptyTag } from '../components/EmptyTag';
import { Input } from "../components/Input";
import { LOADING } from "../components/Loading";
import { ShowToast } from "../components/Toast";
import template from '../templates/EventEvaluation.html?raw';
import ic_logro from '/icons/event/use-icon-logro.svg?raw';
import ic_done_selected from '/icons/ic_done_selected.svg?raw';

interface ICalificacionP extends ICalificacionPendiente {
    _FechaInicioFmt?: string;
    _FechaEval?: Date | "today";
    _Observacion?: string;
    _Calificacion?: string;
    _EvaluacionesConfig?: { Nombre: string; Descripcion: string; }[];
    _NA?: boolean;
}

/** { [`key`]: [`selector`, `label`] } */
const TableInfoCols: { [k in keyof Partial<ICalificacionP>]: [string, string] } = {
    "Materia": [".materia", _L("evaluacion.materia")],
    "_FechaInicioFmt": [".fecha_inicio", _L("evaluacion.fecha_inicio")],
    "Criterio": [".criterio", _L("evaluacion.criterio")],
    "_Calificacion": [".eval", _L("evaluacion.eval")],
    "_FechaEval": [".fecha_eval", _L("evaluacion.fecha_eval")],
    "_Observacion": [".observacion", _L("evaluacion.observacion")],
}

export class EventEvaluation extends BaseUIEvent {
    private pendientesList: ICalificacionP[];
    private trTemplate: HTMLTableRowElement;
    private emptyTag: EmptyTag;

    constructor() {
        super('ui-event-evaluation', EEventId.EVALUATION);
        this.pendientesList = [];

        this.InitUI();
        this.AddSaveBtn();
    }

    private InitUI() {
        this.bodyContainer.html(template);

        const nTrLoading = 5;
        const tBody = this.bodyContainer.select<HTMLTableSectionElement>("tbody");
        this.trTemplate = tBody.select<HTMLTableRowElement>("tr").remove().node();
        select(this.trTemplate).select(".select").append("div")
            .classed("select-indicator", true)
            .html(ic_done_selected)
        this.emptyTag = new EmptyTag(this.bodyContainer.node())
            ._SetText(_L("evaluacion.pendientes_empty"))
            ._SetSVGIcon(ic_logro)

        for (const [selector, label] of Object.values(TableInfoCols)) {
            this.bodyContainer.select<HTMLTableCellElement>("thead > tr " + selector).text(label);
        }

        for (let i = 0; i < nTrLoading; i++) {
            const trLoad = this.trTemplate.cloneNode(true) as HTMLTableRowElement;
            tBody.append(() => trLoad);
            trLoad.classList.add("animated-background");
        }

        const { IdChild } = this.childList[0] || {}
        _LoadPendientes()
            .then((datos) => {
                this.pendientesList = datos
                    .filter(d => d.IdAlumno == IdChild)
                // .sort((a, b) => (ascending((a.Materia + a.Criterio + a.DtInicio), (b.Materia + b.Criterio + b.DtInicio))));
                this.pendientesList.forEach(d => {
                    this.InitDatum(d)
                });
                this.RefreshRows();
            })
            .catch((e) => {
                console.error(e);
                ShowToast(_L("evaluacion.title"), _HttpMsg("calificacion/ObtenerPendientes", -1, "consulta"), "error");
                this.pendientesList = [];
                this.RefreshRows();
            })
    }

    private InitDatum(d: ICalificacionP) {
        d._Calificacion = undefined
        d._NA = false
        d._FechaInicioFmt = _DateFormatStandar(d.DtInicio, "dd/mm/yy")
        d._FechaEval = "today"
        d._Observacion = ""
        d._EvaluacionesConfig = d.EvalNombre.reduce((res, nombre, i) => {
            res.push({
                Nombre: nombre,
                Descripcion: d.EvalDescripcion[i],
            })
            return res;
        }, <ICalificacionP["_EvaluacionesConfig"]>[])
    }

    private RefreshRows() {
        const tBody = this.bodyContainer.select<HTMLTableSectionElement>("tbody");
        tBody.selectAll<HTMLTableRowElement, ICalificacionP>(":scope>tr")
            .data(this.pendientesList, d => d?.Id)
            .join(
                enter => this.RefreshRow(enter.append(() => this.trTemplate.cloneNode(true) as HTMLTableRowElement)),
                update => this.RefreshRow(update),
                exit => exit.remove(),
            )
        if (this.pendientesList.length)
            this.emptyTag._Remove();
        else
            this.emptyTag._ShowTag();
    }

    private RefreshRow(tr: TSelection<"tr", ICalificacionP>) {
        type TRowEvalStatus = "eval" | "eval_empty" | "no_apply"
        const fnUpdateRow = (tr: HTMLTableRowElement, d: ICalificacionP, type: TRowEvalStatus, inputFechaEval: HTMLInputElement) => {
            const selectIndicator = tr.querySelector<HTMLDivElement>(".select-indicator")
            const fnAddClearFunction = () => {
                selectIndicator.style.cursor = "pointer"
                selectIndicator.onclick = () => {
                    this.InitDatum(d);
                    tr.classList.remove("active");
                    tr.classList.remove("no_apply");
                    this.RefreshRow(select(tr))
                }
            }
            const fnRemoveClearFunction = () => {
                selectIndicator.style.cursor = "default"
                selectIndicator.onclick = null
            }

            switch (type) {
                case "eval":
                    tr.classList.add("active");
                    tr.classList.remove("no_apply");
                    fnAddClearFunction();
                    d._NA = false
                    break;
                case "eval_empty":
                    tr.classList.remove("active");
                    fnRemoveClearFunction();
                    break;
                case "no_apply":
                    tr.classList.remove("active");
                    tr.classList.add("no_apply");
                    d._NA = true
                    d._Calificacion = ""
                    fnAddClearFunction();
                    break;
            }
            if (type == "eval_empty") {
                inputFechaEval.min = null
                inputFechaEval.max = null
                inputFechaEval.required = false
            } else {
                inputFechaEval.min = _DateToInputFmt(new Date(d.DtInicio))
                inputFechaEval.max = _DateToInputFmt()
                inputFechaEval.required = true
            }
        }
        return tr.each((d, i, trs) => {
            const tr = trs[i];
            let inputFechaEval: HTMLInputElement
            // tr.classList.remove("animated-background");
            for (const _k in TableInfoCols) {
                const prop = _k as keyof ICalificacionP;
                const [selector] = TableInfoCols[prop];
                const td = tr.querySelector<HTMLTableCellElement>(selector);

                switch (prop) {
                    case "Criterio":
                        td.textContent = d[prop] || _L("evaluacion.no_crit");
                        break;
                    case "_FechaEval":
                        td.innerHTML = "";
                        const inputDtEval = new Input(td, {
                            inputType: "date",
                            min: _DateToInputFmt(new Date(d.DtInicio)),
                            max: _DateToInputFmt(),
                            value: _DateToInputFmt(FixedDateEval(d._FechaEval)),
                            required: true,
                        })
                        inputFechaEval = inputDtEval.input.node() as HTMLInputElement

                        inputFechaEval.oninput = () => {
                            this.OnChangeFechaEval(d, inputFechaEval)
                        }
                        break;
                    case "_Calificacion":
                        td.innerHTML = "";
                        switch (d.EvalTipo) {
                            case ECalificacionTipoEval.Numeros:
                                const inputNum = new Input(td, {
                                    inputType: "number",
                                    min: d.EvalNombre[0] || "",
                                    max: d.EvalNombre[1] || "",
                                    step: "0.01",
                                    value: d._Calificacion,
                                });
                                inputNum.input.on("input", () => {
                                    d._Calificacion = inputNum.value || "";
                                    fnUpdateRow(tr, d, (d._Calificacion ? "eval" : "eval_empty"), inputFechaEval)
                                })
                                inputNum.SetDefaultOptions([_L("general.no_apply")])
                                    .OptionsOnStepItem((cont) => cont.html(`<i>${cont.datum()}</i>`))
                                    .OptionsOnSelect(() => fnUpdateRow(tr, d, "no_apply", inputFechaEval))
                                break;
                            default:
                                const esTipoColor = d.EvalTipo == ECalificacionTipoEval.Colores;
                                const esTipoCualitativo = d.EvalTipo == ECalificacionTipoEval.Cualitativa;
                                const inputCSel = new Input(td, { disabled: true, inputType: (!esTipoColor ? "textarea" : "text") });
                                const input = inputCSel.input.node() as HTMLInputElement;
                                const evaluacionesFinal = d._EvaluacionesConfig.concat({
                                    Nombre: null,
                                    Descripcion: _L("general.no_apply")
                                })
                                inputCSel.SetDefaultOptions(evaluacionesFinal)
                                    .OptionsOnStepItem((item) => {
                                        const d = item.datum()
                                        if (d.Nombre === null) {
                                            item.html(`<i>${d.Descripcion}</i>`);
                                            return
                                        }
                                        if (esTipoColor) {
                                            item.append("div").attr("class", "circle").style("background-color", d.Nombre)
                                            item.append("span").text(d.Descripcion)
                                        }
                                        else if (esTipoCualitativo)
                                            item.html(d.Nombre);
                                        else
                                            item.html(`<b>${d.Nombre}</b>` + (d.Descripcion ? ": " + d.Descripcion : ""));
                                    })
                                    .OptionsMinWidth("120px")
                                    .OptionsMaxWidth("230px")
                                    .OptionsWidth("max-content")
                                    .OptionsOnSetInputText((d) => d.Nombre)
                                    .OptionsOnSelect((item) => {
                                        d._Calificacion = item.Nombre || "";
                                        const evalStatus: TRowEvalStatus = (item.Nombre === null) ? "no_apply" : (d._Calificacion) ? "eval" : "eval_empty"
                                        fnUpdateRow(tr, d, evalStatus, inputFechaEval)
                                    });

                                if (esTipoColor) {
                                    input.type = "color";
                                    input.value = d._Calificacion || "#ffffff";
                                } else {
                                    input.value = d._Calificacion || "";
                                }
                                inputCSel.lblInput.node().onclick = (e) => {
                                    if (e.target != inputCSel.lblInput.node() && e.target != input) return
                                    e.stopPropagation();
                                    if (inputCSel.OptionsListIsVisible())
                                        inputCSel.HideOptionsList();
                                    else
                                        inputCSel.ShowOptionsList();
                                }
                                break;
                        }
                        break;
                    case "_Observacion":
                        td.innerHTML = "";
                        const txtObservacion = new Input(td, { inputType: "textarea" }).input.node() as HTMLTextAreaElement
                        txtObservacion.oninput = () => {
                            d[prop] = txtObservacion.value;
                        }
                        break;
                    default:
                        td.textContent = d[prop] ? d[prop] + "" : "";
                        break;
                }

                tr.onclick = () => console.debug(d);
            }
        })
    }

    private OnChangeFechaEval(d: ICalificacionP, inputFechaEvalElement: HTMLInputElement) {
        if (!inputFechaEvalElement.valueAsDate) {
            d._FechaEval = null
            return
        }
        const dtNow = new Date()
        const dtEval = new Date(inputFechaEvalElement.valueAsDate.toISOString().replace("Z", ""))
        if (dtNow.getFullYear() == dtEval.getFullYear() && dtNow.getMonth() == dtEval.getMonth() && dtNow.getDate() == dtEval.getDate()) {
            d._FechaEval = "today"
        } else
            d._FechaEval = dtEval
    }

    private ValidaInputs() {
        const someInvalid = this.bodyContainer.selectAll<HTMLInputElement | HTMLTextAreaElement, any>(":scope input, :scope textarea").nodes()
            .find(input => !input.validity.valid);

        if (someInvalid) {
            someInvalid.scrollIntoView({ behavior: "smooth", block: "center" })
            console.warn(someInvalid)
        }

        return !someInvalid;
    }

    protected OnSaveClick(): void {
        if (this.childList.length === 0) {
            ShowToast(_L("comment.title"), _L("comment.alumnos_required"), "info");
            return;
        }

        const evalsRegistrar = this.pendientesList.filter(d => d._Calificacion || d._NA);

        if (!evalsRegistrar.length) {
            ShowToast(_L("evaluacion.title"), _L("evaluacion.evals_required"), "warn");
            return;
        }

        if (!this.ValidaInputs()) {
            ShowToast(_L("evaluacion.title"), _L("evaluacion.invalid_data"), "error");
            return;
        }

        const res = _AsignarCalificacion(
            this.IS_EXTEMPORAL,
            this.childList[0].IdChild,
            evalsRegistrar.map(d => ({
                Id: d.Id,
                IdAsignacion: d.IdAsignacion,
                Calificacion: d._Calificacion,
                Observacion: d._Observacion,
                FechaEval: FixedDateEval(d._FechaEval).toISOString(),
            })));

        LOADING.Show();
        res
            .then(res => {
                const success = res.Resultado > 0;
                let msg = _HttpMsg("calificacion/Asignar", res.Resultado)
                if (res.Resultado == -14) {
                    msg += ":\n "
                    msg += evalsRegistrar
                        .filter((_, i) => res.IndexFechasInasistencia.includes(i))
                        .map(d => _DateFormatStandar(FixedDateEval(d._FechaEval)))
                        .join(", ")
                }
                ShowToast(_L("evaluacion.title"), msg, (success ? 'success' : "error"));
                if (success) history.back();
            })
            .catch(() => {
                ShowToast(_L("evaluacion.title"), _HttpMsg("calificacion/Asignar", -123), "error");
            })
            .finally(() => {
                LOADING.Dismiss();
            })
    }
}

function FixedDateEval(fechaEval: ICalificacionP["_FechaEval"]) {
    if (fechaEval == "today") {
        return new Date()
    }
    return fechaEval
}
