import "../../../styles/pages/Extemp.scss";
import { _APP_DATA } from "../../data/AppData";
import { _LoadExtempDataChild } from "../../data/services/Kids";
import { _HttpMsg, _Label as _L } from "../../utils/Labels";
import { BClsRoom } from "../bases/BClsRoom";
import { LOADING } from "../components/Loading";
import { ShowToast } from "../components/Toast";
import ic_extemporaneos from '/icons/ic_extemporaneos.svg?raw'

export class Extemporaneos extends BClsRoom {

    constructor() {
        super();

        this.IS_EXTEMPORAL = true;
    }

    protected LoadChild(): void {
        this.emptyTag
            ._SetColor("var(--color-extemporaneo)")
            ._SetText(_L("extemporal.no_childs"))
            ._SetSVGIcon(ic_extemporaneos)

        _LoadExtempDataChild((status, resList) => {
            LOADING.Dismiss();
            if (status < 0) {
                ShowToast(_L("extemporal.child_extemp"), _HttpMsg("alumno/ObtenerExtemporaneosDisponiblesHoy", status), 'error');
            }

            this.childList = resList;

            if (this.childList?.length == 0) {
                this.RefreshEmptyTag()
                return;
            }

            this.SetListBubbleChild();
            this.DragAllItems();

            this.RefreshEmptyTag()
        })
    }

    protected CreateHeader(addOptions: boolean): void {
        super.CreateHeader(addOptions);

        let title = _APP_DATA.userData.NombreGrupo;
        const teacherName = _APP_DATA.userData.NombreMaestra + " " + _APP_DATA.userData.ApPaternoMaestra + " " + _APP_DATA.userData.ApMaternoMaestra;
        if (title && teacherName.trim()) title += " - "
        title += teacherName.trim() != "" ? teacherName : "";
        title += ` (${_L("extemporal.title")})`;

        this.headerContainer.select(".title").html(`<span class="status-extemp"></span>` + title);
    }

    private RefreshEmptyTag() {
        if (this.childList?.length) {
            this.emptyTag._Remove()
        } else {
            this.emptyTag._ShowTag()
        }
    }
}