import { Selection, select } from "d3";
import "../../../styles/pages/Groups.scss";
import { _APP_DATA } from "../../data/AppData";
import { _SetGroupInUserInfo } from "../../data/ManageUserInfo";
import { IGroup } from "../../data/models/Entities";
import { _LoadUnreadedMessage, _mapUnreadedMsgByGroup } from "../../data/services/Chats";
import { _ClearEventsData } from "../../data/services/Event";
import { _ClearMaestrosInfoData, _LoadGroups } from "../../data/services/Group";
import { _ClearHitsData } from "../../data/services/Hits";
import { _ClearChildsData } from "../../data/services/Kids";
import { _ClearKinderData } from "../../data/services/Kinder";
import { _HashReplace } from "../../routes/UIManager";
import _L from "../../utils/Labels";
import { BaseUI } from "../bases/BaseUI";
import { Button } from "../components/Button";
import { EmptyTag } from "../components/EmptyTag";
import { LOADING } from "../components/Loading";
import ic_home from '/icons/ic_home.svg?raw';


export class Groups extends BaseUI {

    private listGroupsContainer: Selection<HTMLDivElement, any, any, any>;

    private timmerLoadGroup: number = 1000 * 60;
    private idTimeoutLoadGroup: NodeJS.Timeout;
    private listGroups: Array<IGroup> = [];
    private isMainActive: boolean = true;
    private isSecundaryActive: boolean = true;
    private emptyTag: EmptyTag

    constructor() {
        super({ addMainLogoOptions: false, addOptionsInHeader: false, addFooter: true, className: "main_groups" });

        this.listGroupsContainer = this.bodyContainer.append("div").classed("list-bubbles-group", true)
        this.emptyTag = new EmptyTag(this.listGroupsContainer.node())
            ._SetSVGIcon(ic_home)

        LOADING.Show();

        this.SetBubblesGroup(this.listGroups);
        this.CreateButtons();

        this.Init();
    }


    Init() {
        this.LoadData();

        this.idTimeoutLoadGroup = setInterval(() => {
            this.LoadData();
        }, this.timmerLoadGroup);
    }

    LoadData() {
        _LoadGroups((result) => {
            if (!result) {
                this.emptyTag._SetText(_L("general.error"))
            } else if (!result.length) {
                this.emptyTag._SetText(_L("home.without_groups"))
            }
            this.listGroups = result || [];
            this.FilterGroups();

            this.CheckMessageUnreaded();

            LOADING.Dismiss();
        });
    }

    private CheckMessageUnreaded() {
        this.listGroups.forEach(item => {
            _LoadUnreadedMessage((_) => {
                this.FilterGroups();
            }, item.IdGrupo);
        });
    }

    private SetBubblesGroup(listData: IGroup[]) {
        const bubblesgroup = this.listGroupsContainer.selectAll<HTMLDivElement, any>(".bubbles-group").data(listData);
        if (!listData.length) {
            this.emptyTag._ShowTag()
        } else {
            this.emptyTag._Remove()
        }

        // const self = this;
        bubblesgroup.exit().remove();
        bubblesgroup.enter().append("div")
            .classed("bubbles-group", true)
            .each((_, i, divs) => {
                const elemnt = select(divs[i]);
                let bubble = elemnt.append("div").classed("bubble-group", true);
                bubble.append("h2");
                bubble.append("span").classed("n-messages", true);

            })
            .merge(bubblesgroup)
            .on('click', (_, d) => this.OnSelectedGroup(d))
            .each((datum, i, divs) => {
                const elemnt = select(divs[i]).select(".bubble-group");
                elemnt.classed("main", datum.EsPrincipal)
                elemnt.select("h2").text(datum.NombreGrupo)

                elemnt.select(".n-messages")
                    .text(datum.NMessages)
                    .classed("active", datum.NMessages > 0)
            });
    }

    private CreateButtons() {
        const btnContainer = this.footerContainer.append("div").classed("list-buttons-group", true);
        const btnMain = new Button(btnContainer)
            .Title(_L("home.g_prim"))
            .Classed("main")
            .Classed("active")
            .OnClick(() => {
                const isActive = btnMain._button.classed("active");
                this.isMainActive = !isActive;
                btnMain._button.classed("active", this.isMainActive);

                this.FilterGroups();
            });

        const btnSecundary = new Button(btnContainer)
            .Title(_L("home.g_sec"))
            .Classed("secundary")
            .Classed("active")
            .OnClick(() => {
                const isActive = btnSecundary._button.classed("active");
                this.isSecundaryActive = !isActive;
                btnSecundary._button.classed("active", this.isSecundaryActive);

                this.FilterGroups();
            });
    }

    private FilterGroups() {
        var newData: Array<IGroup> = []
        if (this.isMainActive)
            newData = newData.concat(this.listGroups.filter(obj => obj.EsPrincipal));

        if (this.isSecundaryActive)
            newData = newData.concat(this.listGroups.filter(obj => !obj.EsPrincipal));

        newData = newData.sort((a, b) => a.IdGrupo - b.IdGrupo);

        newData.map(o => o.NMessages = _mapUnreadedMsgByGroup.get(o.IdGrupo)?.countMsg || 0);

        this.SetBubblesGroup(newData);
    }

    private OnSelectedGroup(datum: IGroup) {
        LOADING.Show();

        this.EvaluateConfig(datum.IdNivel, (valid) => {
            if (!valid) return;
            const cambioEscuela = datum.IdEscuela != _APP_DATA.userData.IdKinder;

            _SetGroupInUserInfo(datum);
            _ClearChildsData();
            _ClearEventsData();
            _ClearMaestrosInfoData();

            if (cambioEscuela) {
                _ClearKinderData();
                _ClearHitsData();
            }

            LOADING.Dismiss();
            _HashReplace(datum.EsPrincipal ? "home" : "classroom");
        });
    }

    public OnDestroy(): void {
        clearInterval(this.idTimeoutLoadGroup);
        this.idTimeoutLoadGroup = null;
    }

}